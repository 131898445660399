import React from "react";
import "./App.css";
import "./styles/sb-admin-2.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Users from "./components/pages/Users";
import Teachers from "./components/pages/Teacher";
import Article from "./components/pages/Articles";
import Login from "./components/pages/Login";
import Plans from "./components/pages/Plans";
import NewPlans from "./components/pages/Plans/New";
import PrivateRoute from "./components/templates/PrivateRoute";
import Store from "./store/Store";
import NewArticle from "./components/pages/NewArticle";
import EditArticle from "./components/pages/EditArticle";

const App: React.FC = () => {
  return (
    <Store>
      <div className="App" id="wrapper">
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Users} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/teachers" component={Teachers} />
            <PrivateRoute exact path="/plans" component={Plans} />
            <PrivateRoute exact path="/plans/new" component={NewPlans} />
            <PrivateRoute exact path="/articles" component={Article} />
            <PrivateRoute exact path="/articles/new" component={NewArticle} />
            <PrivateRoute
              exact
              path="/articles/:articleId"
              component={EditArticle}
            />
          </Switch>
        </Router>
      </div>
    </Store>
  );
};

export default App;
