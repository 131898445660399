import React from "react";
import TopCard from "../../atoms/TopCard/TopCard";
import AdminTemplate from "../../templates/AdminTemplate";
import TeacherTable, {
  TeacherTableContent
} from "../../molecules/Table/TeacherTable";

const Teacher: React.FC = () => {
  const teacherList: TeacherTableContent[] = [
    {
      id: 1,
      name: "マルタ・マルティン・モリーナ",
      nationality: "スペイン",
      goodCategory: "流暢に話せるスペイン語",
      email: "info@spacom.jp"
    },
    {
      id: 2,
      name: "中園竜之介",
      nationality: "日本",
      goodCategory: "スペイン語業務",
      email: "info@spacom.jp"
    }
  ];
  return (
    <AdminTemplate>
      <h1 className="h3 mb-2 text-gray-800">講師</h1>
      <div className="row">
        <TopCard
          title="講師"
          text={teacherList.length.toString()}
          icon="user-chart"
          class="danger"
        />
      </div>
      <TeacherTable objects={teacherList} />
    </AdminTemplate>
  );
};

export default Teacher;
