import React from "react";
import Table, { TableContent, TableRow } from "./Table";
import { TitledCard } from "../../../components/organisms/TitledCard";

export interface TeacherTableContent {
  id: number;
  name: string;
  nationality: string;
  goodCategory: string;
  email: string;
}

export interface TeacherTableProps {
  objects: TeacherTableContent[];
}

const TeacherTable: React.FC<TeacherTableProps> = ({ objects }) => {
  const header: TableContent = {
    items: ["名前", "国籍", "得意分野", "メール"]
  };
  const contents: TableRow[] = objects.map(
    ({ id, name, email, nationality, goodCategory }) => {
      return {
        id: id,
        items: [name, nationality, goodCategory, email]
      };
    }
  );
  return (
    <TitledCard title={"講師"}>
      <Table header={header} contents={contents} />
    </TitledCard>
  );
};

export default TeacherTable;
