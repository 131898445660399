import React from "react";
import { SideBarSectionItem } from "../../atoms/SideBarSectionItem";
import { MenuProps } from "../../../common/types/SideBar.types";
import styled from "styled-components";

interface SideBarSectionProps {
  sectionItems: MenuProps[];
}

const SideBarSection: React.FC<SideBarSectionProps> = ({ sectionItems }) => {
  return (
    <div>
      {sectionItems.map(sectionItem => (
        <SideBarSectionItem
          key={sectionItem.title}
          title={sectionItem.title}
          to={sectionItem.to}
          isActive={false}
        />
      ))}
      <li>
        <RemoteHour href="https://remotehour.com/dashboard" target="_blank">
          <SideBarNavigationHover>カウンセリング</SideBarNavigationHover>
        </RemoteHour>
      </li>
    </div>
  );
};

export default SideBarSection;
const RemoteHour = styled.a`
  color: red;
  display: block;
  width: 100%;
  text-align: left;
  width: 14rem;
  position: relative;
  margin: 16px 0;
`;

const SideBarNavigationHover = styled.span`
  ${RemoteHour}:hover & {
    color: red;
    font-weight: 700;
  }
  padding-left: 16px;
`;
